<template>
<section class="container is-max-desktop">
    <b-breadcrumb
    size="is-small"
    align="is-left"
>
    <b-breadcrumb-item tag="router-link" to="/">Home</b-breadcrumb-item>
    <b-breadcrumb-item
        tag="router-link"
        to="/full-day-tours"
        active
    >
        Full day Tours
    </b-breadcrumb-item>
</b-breadcrumb>

    <h1 class="has-text-weight-semibold mb-5 is-size-4">
        Full Day Tours With a Private Guide
      </h1>

    <div v-if="similarActivities.length" class="mb-3 mt-4">
        <TicketsAndActivitiesContainer
          is-full-width
          :is-experiences-ids="false"
          :Experiences="similarActivities"
          :Tickets="[]"
        />
        <RequestTour class="mt-3"/>
      </div>
      <p class="mb-3 is-size-5 has-text-weight-semibold mb-5 mt-6">Other Categories</p>
<CategoriesList/>
    
</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
    ActivitiesActions,
  ActivitiesStates,
} from "../store/Storetypes";
import TicketsAndActivitiesContainer from "../components/TicketsAndActivitiesContainer.vue";
import RequestTour from "../components/elements/RequestTour.vue";
import CategoriesList from "./CategoriesList.vue";
export default{
    components: {
    TicketsAndActivitiesContainer,
    RequestTour,
    CategoriesList
  },
  metaInfo() {
    return {
      title: "The Best Full Day Tours for Exploring Portugal",
      titleTemplate: `The Best Full Day Tours for Exploring Portugal | Guiders `,
      keywords: "full day tours in Portugal, best full day tours Portugal, Portugal full day excursions, top Portugal day tours, Portugal one day tours, full day sightseeing Portugal, explore Portugal in a day, full day tours in Lisbon, best full day tours Lisbon, Lisbon full day excursions, top Lisbon day tours, Lisbon one day tours, full day sightseeing Lisbon, explore Lisbon in a day, full day tours in Sintra, best full day tours Sintra, Sintra full day excursions, top Sintra day tours, Sintra one day tours, full day sightseeing Sintra, explore Sintra in a day",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Discover the beauty of Portugal with our expertly guided full day tours. Explore iconic landmarks, vibrant cities, and picturesque countryside. Book your unforgettable adventure today!",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `The Best Full Day Tours for Exploring Portugal | Guiders`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "Discover the beauty of Portugal with our expertly guided full day tours. Explore iconic landmarks, vibrant cities, and picturesque countryside. Book your unforgettable adventure today!",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://guiders.pt/tours/full-day-tours",
        },
      ],
      link: [{ rel: "canonical", href: "https://guiders.pt/tours/full-day-tours" }],
    };
  },
    mounted() {
        window.scrollTo(0, 0);
        this.getActivities()
  },
  computed: {
    ...mapState({
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES] }),
      similarActivities() {
      const activityId = 13
      const otherActivities = this.activities.filter(
        (activity) => activity.categories.includes(activityId)
      );
      return otherActivities
    },
},
  methods: {
    ...mapActions({
            getActivities: ActivitiesActions.GET_ACTIVITIES,
        }),
    
  }
}
</script>

<style lang="scss" scoped>

::v-deep{
    .breadcrumb.is-small {
    font-size: 0.9rem;
}

.breadcrumb a {
    color: #167df0;
}
}

</style>